import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentTextArea.figmaUrl.android} codeUrl={checklists.componentTextArea.codeUrl.android} checklists={checklists.componentTextArea.checklists} mdxType="ChecklistResourcesSection" />
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "align": "center",
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textarea/android-compose-textarea.gif",
            "alt": "Legion Textarea Android Compose"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <div className="divi" />
    <h2>{`Types and States`}</h2>
    <p>{`Legion has 2 type of Textarea`}</p>
    <ol>
      <li parentName="ol">{`Basic Text Area Inline`}</li>
      <li parentName="ol">{`Basic Text Area Outline`}</li>
    </ol>
    <p>{`Legion also has 8 states of Textarea`}</p>
    <ol>
      <li parentName="ol">{`Normal State Inline`}</li>
      <li parentName="ol">{`Normal State Outline`}</li>
      <li parentName="ol">{`Error State Inline`}</li>
      <li parentName="ol">{`Error State Outline`}</li>
      <li parentName="ol">{`Success State Inline`}</li>
      <li parentName="ol">{`Succes State Outline`}</li>
      <li parentName="ol">{`Disable State Inline`}</li>
      <li parentName="ol">{`Disable State Outline`}</li>
    </ol>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`Textarea allow user input. The border should light up simply and clearly indicating which field the user is currently editing.`}</p>
    <h3>{`Inline`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`var value by remember { mutableStateOf("") }

LgnInlineTextArea(
    value = value,
    onValueChange = {
        value = it
    },
    placeholder = "Description"
)
`}</code></pre>
    <h3>{`Outline`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`var value by remember { mutableStateOf("") }

LgnOutlineTextArea(
    value = value,
    onValueChange = {
        value = it
    },
    placeholder = "Description"
)
`}</code></pre>
    <div className="divi" />
    <h2>{`States`}</h2>
    <h3>{`Normal`}</h3>
    <h4>{`Inline`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`var value by remember { mutableStateOf("") }

LgnFieldContainer(label = "Description") {
    LgnInlineTextArea(
        value = value,
        onValueChange = {
            value = it
        },
        placeholder = "Description"
    )
}

`}</code></pre>
    <h4>{`Outline`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`var value by remember { mutableStateOf("") }

LgnFieldContainer(label = "Description") {
    LgnOutlineTextArea(
        value = value,
        onValueChange = {
            value = it
        },
        placeholder = "Description"
    )
}

`}</code></pre>
    <h3>{`Error`}</h3>
    <h4>{`Inline`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`var value by remember { mutableStateOf("") }

LgnFieldContainer(label = "Description", error = "Description Error") {
    LgnInlineTextArea(
        value = value,
        onValueChange = {
            value = it
        },
        placeholder = "Description",
        error = true
    )
}

`}</code></pre>
    <h4>{`Outline`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`var value by remember { mutableStateOf("") }

LgnFieldContainer(label = "Description", error = "Description Error") {
    LgnOutlineTextArea(
        value = value,
        onValueChange = {
            value = it
        },
        placeholder = "Description",
        error = true,
    )
}

`}</code></pre>
    <h3>{`Success`}</h3>
    <h4>{`Inline`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`var value by remember { mutableStateOf("") }

LgnFieldContainer(label = "Description", success = "Description Correct") {
    LgnInlineTextArea(
        value = value,
        onValueChange = {
            value = it
        },
        placeholder = "Description",
        success = true
    )
}

`}</code></pre>
    <h4>{`Outline`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`var value by remember { mutableStateOf("") }

LgnFieldContainer(label = "Description", success = "Description Correct") {
    LgnOutlineTextArea(
        value = value,
        onValueChange = {
            value = it
        },
        placeholder = "Description",
        success = true,
    )
}

`}</code></pre>
    <h3>{`Disabled`}</h3>
    <h4>{`Inline`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`var value by remember { mutableStateOf("") }

LgnInlineTextArea(
    value = value,
    onValueChange = {
        value = it
    },
    placeholder = "Description",
    enabled = false
)

`}</code></pre>
    <h4>{`Outline`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`var value by remember { mutableStateOf("") }

LgnOutlineTextArea(
    value = value,
    onValueChange = {
        value = it
    },
    placeholder = "Description",
    enabled = false
)

`}</code></pre>
    <div className="divi" />
    <h2>{`Attributes`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameters`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`value`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Text in Area`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`onValueChange`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(String) -> Unit`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Action When Text Change`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colors`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`LgnTextFieldColors`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Color of Text`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`textStyle`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TextStyle`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Text Style of Text`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`enabled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Enable or Disable of Text Area`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`error`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set whether Text Area State Error or Not`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`success`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set whether Text Area State Success or Not`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`shape`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Shape`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Shape of Text Area`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`placeHolder`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Place Holder of Text Area`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`keyboardOptions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`KeyboardOptions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Options Available on the Keyboard when They Appear on the Screen`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`keyboardActions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`KeyboardOptions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`What the Application Should Do after the User has Finished Entering Text`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`maxLines`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Int`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Maximum Line of Text Area`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`minLines`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Int`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Minimum Line of Text Area`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`visualTransformation`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`VisualTransformation`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Changing Visual Output of the Text Area`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      